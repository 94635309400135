import React from 'react';
import {
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';
import { useState } from 'react';
import { AddCircle } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { CreateActivityApi } from '../../api/activityService';
import { uploadFileToS3 } from '../../api/s3Service';

/*
 * UI interface to Create an Activity
 * Important fields
 * * Title, Description, Image, Items Required for the activity
 * * Tags, skills gained
 * * Tips and Tricks
 */

const CreateActivity = () => {
  const grades = [
    'KG',
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
  ];
  // Skills to be fetched from database.
  const skills = ['Mathematics', 'Science', 'Analytical', 'Cognitive'];

  const [selectedGrades, setSelectedGrades] = useState([]);
  const [steps, setSteps] = useState(['']);
  const [materials, setMaterials] = useState(['']);
  const [personName, setPersonName] = React.useState([]);
  const [file, setFile] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [activityData, setActivityData] = useState({
    title: '',
    summary: '',
    imgLink: null,
    description: '',
    tags: {
      gradeLevel: [''],
      subject: [''],
    },
    materialsNeeded: '',
    steps: '',
    completionTimeInMin: '',
  });
  const [errors, setErrors] = useState({
    title: '',
    summary: '',
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setActivityData({
      ...activityData,
      tags: {
        ...activityData.tags,
        subject: typeof value === 'string' ? value.split(',') : value,
      },
    });
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleGradeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGrades(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setActivityData({
      ...activityData,
      tags: {
        ...activityData.tags,
        gradeLevel: typeof value === 'string' ? value.split(',') : value,
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFile: 5,
    onDrop: (acceptedFiles) => {
      if (file.length + acceptedFiles.length <= 5) {
        setFile((prevFiles) => [...prevFiles, ...acceptedFiles]);
        const fileNames = acceptedFiles.map((file) => file.name);
        setActivityData({
          ...activityData,
          imgLink: fileNames,
        });
      } else {
        setSnackbarMessage('You can only upload up to 5 files.');
        setSnackbarOpen(true);
      }
    },
    onDropRejected: (rejectedFiles) => {
      setSnackbarMessage('You can only upload up to 5 files.');
      setSnackbarOpen(true);
    },
  });

  const handleAddStep = () => {
    setSteps([...steps, '']);
  };
  const handleAddMaterials = () => {
    setMaterials([...materials, '']);
  };
  const handleStepChange = (index, value) => {
    const updatedSteps = [...steps];
    updatedSteps[index] = value;
    setSteps(updatedSteps);
    setActivityData({
      ...activityData,
      steps: updatedSteps,
    });
  };

  const handleMaterialChange = (index, value) => {
    const updatedMaterials = [...materials];
    updatedMaterials[index] = value;
    setMaterials(updatedMaterials);
    setActivityData({
      ...activityData,
      materialsNeeded: updatedMaterials,
    });
  };
  const handleRemoveFile = (fileToRemove) => {
    setFile(file.filter((file) => file !== fileToRemove));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setActivityData({
      ...activityData,
      [name]: value,
    });
  };
  const validateForm = () => {
    const newErrors = { title: '', summary: '' };
    let isValid = true;

    // Title validation (required)
    if (!activityData.title.trim()) {
      newErrors.title = 'Title is required';
      isValid = false;
    }

    // Summary validation (required and minimum length of 20)
    if (!activityData.summary.trim()) {
      newErrors.summary = 'Summary is required';
      isValid = false;
    } else if (activityData.summary.length < 20) {
      newErrors.summary = 'Summary must be at least 20 characters long';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = async (draftFlag) => {
    if (validateForm()) {
      activityData.status = draftFlag ? 'DRAFT' : 'SUBMIT';
      const activityResp = await CreateActivityApi(activityData);
      const activityId = activityResp.activity._id;
      console.log('activity Id', activityId, activityData);

      if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
          const fileName = file[i].name;
          const mimeType = file[i].type;
          // Set the S3 path with activity ID and the file name
          const filePath = `${activityId}/${fileName}`;

          // Upload the file to S3
          try {
            await uploadFileToS3('knoloomimages', filePath, mimeType);

            console.log('File uploaded successfully to S3:', filePath);
            setSnackbarMessage('Activity created successfully!');
            setSnackbarOpen(true);
          } catch {
            console.error('Error uploading file to S3:', error);
          }
        }
      } else {
        console.error('No file selected');
      }
    } else {
      console.log('foem has validaion errors');
    }
  };
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" align="center">
        Create An Activity
      </Typography>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        required
        name="title"
        value={activityData.title}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
        error={!!errors.title}
        helperText={errors.title}
      />
      <TextField
        label="Summary"
        variant="outlined"
        fullWidth
        required
        multiline
        rows={4}
        name="summary"
        value={activityData.summary}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
        error={!!errors.summary}
        helperText={errors.summary}
      />
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Box
          {...getRootProps()}
          sx={{
            border: '1px solid gray',
            padding: '20px',
            borderRadius: '8px',
            cursor: 'pointer',
            marginBottom: '1%',
          }}
        >
          <Typography variant="h6">
            {file.length ? (
              <Box sx={{ mt: 2 }}>
                {file.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.name}
                    onDelete={() => handleRemoveFile(file)}
                    sx={{ marginRight: '8px', marginBottom: '8px' }}
                  />
                ))}
              </Box>
            ) : (
              'Drag & Drop or Click to Upload upto 5 Images'
            )}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {file.length > 0 && `Total Files: ${file.length}`}
        </Typography>
      </Box>
      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        name="description"
        value={activityData.description}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Grade Level</Typography>
          <Select
            fullWidth
            multiple
            value={selectedGrades}
            onChange={handleGradeChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            sx={{ mb: 2 }}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            // MenuProps={MenuProps}
          >
            {grades.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Skills</Typography>
          <Select
            fullWidth
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            sx={{ mb: 2 }}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {skills.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
            <Typography variant="h6">Steps involved</Typography>
            {steps.map((step, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
              >
                <Typography variant="body1">{index + 1}.</Typography>
                <TextField
                  label={`Step ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  rows={2}
                  value={step}
                  onChange={(e) => handleStepChange(index, e.target.value)}
                  sx={{ ml: 2 }}
                />
              </Box>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircle />}
              onClick={handleAddStep}
              sx={{ mt: 2 }}
            >
              Add More Steps
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
            <Typography variant="h6">Materials Required</Typography>
            {materials.map((step, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
              >
                <Typography variant="body1">{index + 1}.</Typography>
                <TextField
                  // label={`Step ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  rows={2}
                  value={step}
                  onChange={(e) => handleMaterialChange(index, e.target.value)}
                  sx={{ ml: 2 }}
                />
              </Box>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircle />}
              onClick={handleAddMaterials}
              sx={{ mt: 2 }}
            >
              Add More Materials
            </Button>
          </Box>
        </Grid>
      </Grid>

      <TextField
        label="Completion Time"
        fullWidth
        name="completionTimeInMin"
        value={activityData.completionTimeInMin}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: 3,
          button: {
            flex: 'none',
          },
        }}
      >
        <Button
          variant="outlined"
          sx={{ mr: 1 }}
          onClick={() => handleSubmit(true)}
        >
          Save as Draft
        </Button>
        <Button variant="outlined" sx={{ mr: 1 }}>
          Preview
        </Button>
        <Button variant="contained" onClick={() => handleSubmit(false)}>
          Submit
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        sx={{
          backgroundColor:
            snackbarMessage === 'Activity created successfully!'
              ? '#4CAF50'
              : '#f44336',
          color: 'white', // Ensure text is white
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={
            snackbarMessage === 'Activity created successfully!'
              ? 'success'
              : 'error'
          }
          sx={{
            width: '100%',
            backgroundColor:
              snackbarMessage === 'Activity created successfully!'
                ? '#4CAF50'
                : '#f44336',
            color: 'white',
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateActivity;
