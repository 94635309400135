export const BASE_URL = process.env.REACT_APP_API_URL; //'http://localhost:9001'; //

const ENDPOINTS = {
  /* All the backend API endpoints will be listed here */
  /* Auth Urls */
  LOGIN: `${BASE_URL}/api/signin`,
  REGISTER: `${BASE_URL}/api/signup`,
  LOGOUT: `${BASE_URL}/api/logout`,
  /* Blog Urls */
  CREATE_BLOG: `${BASE_URL}/api/createblog`,
  LIST_BLOGS: `${BASE_URL}/api/listblogs`,
  BLOG_DETAIL: (id) => `${BASE_URL}/api/blogs/${id}`,
  /* Blog Comments Urls */
  COMMENTS: `${BASE_URL}/api/comments`,
  COMMENT_DETAIL: (id) => `${BASE_URL}/api/comments/${id}`,
  /* */
  SURVEY_GET: (id) => ``,
  /*Activity URL*/
  CREATE_ACTIVITY: `${BASE_URL}/api/createActivity`,

  S3_UPLOAD_URL: (bucketname, filename, mimeType) =>
    `${BASE_URL}/api/s3-upload-url?s3Bucket=${encodeURIComponent(bucketname)}&filename=${encodeURIComponent(filename)}&mimeType=${encodeURIComponent(mimeType)}`,
  S3_DOWNLOAD_URL: (bucketname, filename) =>
    `${BASE_URL}/api/s3-download-url?s3Bucket=${encodeURIComponent(bucketname)}&filename=${encodeURIComponent(filename)}`,
};

export default ENDPOINTS;
