import apiClient from './apiClient';
import ENDPOINTS from './endpoints';

export const fetchActivityData = async (page, limit = 10, grades, subjects) => {
  try {
    const subject =
      subjects !== undefined && subjects !== null && subjects.length > 0
        ? subjects.split('>').slice(-1)[0].trim()
        : '';
    const serializedData = encodeURIComponent(
      JSON.stringify({ grades, subject })
    );
    const response = await apiClient.get(
      `${ENDPOINTS.LIST_ACTIVITIES}?page=${page}&limit=${limit}&data=${serializedData}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching Activites:', error);
    throw error;
  }
};

export const fetchActivityDetails = async (id) => {
  try {
    const response = await apiClient.get(ENDPOINTS.GET_ACTIVITY, {
      headers: {
        'x-api-key': 'skykaApi',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching activity details:', error);
    throw error;
  }
};

export const CreateActivityApi = async (values) => {
  try {
    const response = await apiClient.post(ENDPOINTS.CREATE_ACTIVITY, values);
    console.log('API response:', response.data);
    window.alert('Activity added successfully!');
    return response.data;
  } catch (error) {
    console.error('API error:', error);
  }
};
