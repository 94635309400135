import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Pagination,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from '@mui/material';

import kidsPlayingImage from '../../assets/home/kids_playing.jpg';
import { motion } from 'framer-motion';
import { useMediaQueryContext } from '../../context/MediaQueryProvider';

export const formatCounts = (count) => {
  if (count < 1000) return count;
  if (count < 1000000) return (count / 1000).toFixed(1) + 'K';
  return (count / 1000000).toFixed(1) + 'MM';
};

const CustomPagination = ({ count, page, onPageChange }) => {
  return (
    <Stack spacing={2} direction="row" justifyContent="center" mt={2}>
      <Pagination
        count={count}
        page={page}
        onChange={(event, value) => onPageChange(value)}
        color="primary"
      />
    </Stack>
  );
};

// Individual BlogItem component.
const BlogItem = ({ blog, descriptionLength }) => {
  const {
    title,
    author,
    createdAt,
    viewCount,
    likeCount,
    commentIds,
    summary,
    imageUrl,
  } = blog;

  return (
    <Card
      sx={{
        width: '350px', // Increased width for a more rectangular shape
        margin: '10px', // Space between cards
        borderRadius: '16px', // Softer curved edges
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for better aesthetics
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)', // Slight zoom effect on hover
        },
      }}
    >
      {/* Blog Image */}
      <Box
        component="img"
        src={imageUrl}
        alt={title}
        sx={{
          width: '100%',
          height: '220px',
          objectFit: 'cover',
          display: 'block',
        }}
      />

      {/* Blog Title */}
      <Box
        sx={{
          position: 'absolute',
          top: '140px', // Adjust to overlap with the image
          left: 0,
          width: '100%',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          padding: '8px 0',
          overflow: 'hidden', // Ensures content does not spill out
        }}
      >
        <Typography
          variant="h6"
          color="white"
          sx={{
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            px: 2,
            transition: 'all 0.3s ease',
            display: 'inline-block',
            '&:hover': {
              animation: 'scrollTitle 5s linear infinite',
            },
          }}
          className="scrollable-title"
        >
          {blog.title}
        </Typography>
      </Box>

      {/* Card Content */}
      <CardContent>
        {/* Author and Date */}
        <Typography variant="body2" color="textSecondary" align="center">
          By {author} | {new Date(createdAt).toLocaleDateString()}
        </Typography>

        {/* Views and Likes */}
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ marginTop: 1 }}
        >
          👁️ {formatCounts(viewCount)} • 👍 {formatCounts(likeCount)} • 💬{' '}
          {formatCounts(commentIds.length)}
        </Typography>
      </CardContent>
    </Card>
  );
};

const BlogListView = ({ blogData, blogsPerPage }) => {
  // Initial setup
  const isMobile = useMediaQueryContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [descriptionLength] = useState(100);
  // Mobile blog sorting based on selection.
  const [sortedBlogs, setSortedBlogs] = useState([]);
  const [sortType, setSortType] = useState('createdDate');

  useEffect(() => {
    // Sort blogs based on the selected sortType
    const sorted = blogData.sort((a, b) => {
      if (sortType === 'likes') {
        return b.likeCount - a.likeCount;
      }
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setSortedBlogs(sorted);
  }, [sortType]);

  // Pagination calculations
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  // Sort the blogs based on likes and then slice it.

  const currentBlogs = (
    isMobile ? sortedBlogs : blogData.sort((a, b) => b.likeCount - a.likeCount)
  ).slice(indexOfFirstBlog, indexOfLastBlog);
  const totalPages = Math.ceil(blogData.length / blogsPerPage);

  // handle page change through the object of pagination component
  // On page select, scroll to top of the window.
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: 'rgba(173, 216, 230, 0.3)', // Blue-tinted background
      }}
    >
      {/* Header */}
      <Typography variant="h4" align="center" sx={{ marginBottom: '24px' }}>
        Recent Blogs
      </Typography>

      {/* Blog Grid */}
      <Grid
        container
        rowSpacing={7}
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        {currentBlogs.map((blog) => (
          <Grid
            key={blog._id}
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {/* <BlogCard blog={blog} /> */}
            <Link
              to={`/blogs/${blog._id}`}
              style={{ textDecoration: 'none' }}
              key={blog._id}
            >
              <BlogItem blog={blog} descriptionLength={descriptionLength} />
            </Link>
          </Grid>
        ))}
      </Grid>
      <Stack spacing={2} style={{ my: '20px', alignItems: 'center' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          color="primary"
          size={isMobile ? 'small' : 'large'}
        />
      </Stack>
    </Container>
  );
};

export default BlogListView;
