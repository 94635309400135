import React, { useState } from 'react';
import { TextField, Button, IconButton, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircleOutline } from '@mui/icons-material';
import { lightBlue } from '@mui/material/colors';
import ImageUpload from '../../components/img/ImageUpload';

function CreateBlog() {
  const [blogData, setBlogData] = useState({
    title: '',
    summary: '',
    titleImage: null,
    sections: [
      {
        sectionTitle: '',
        sectionContent: '',
        sectionImage: null,
        imagePosition: '',
        tips: '',
        funFact: '',
        subsections: [],
      },
    ],
    resource: '',
    disclaimer: '',
    tags: '',
    status: '',
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e, path) => {
    const { value } = e.target;

    setBlogData((prevData) => {
      const updatedData = { ...prevData };
      const parts = path.split('.');

      parts.reduce((acc, part, i) => {
        // Check if this part is an array index
        const arrayMatch = part.match(/^(.+)\[(\d+)\]$/);

        if (arrayMatch) {
          const arrayName = arrayMatch[1];
          const index = parseInt(arrayMatch[2], 10);

          // Ensure the array exists
          if (!acc[arrayName]) acc[arrayName] = [];
          // Ensure the array item exists
          if (!acc[arrayName][index]) acc[arrayName][index] = {};

          // If this is the last part, set the value
          if (i === parts.length - 1) {
            acc[arrayName][index] = value;
          }

          return acc[arrayName][index];
        } else {
          // If this is the last part, set the value
          if (i === parts.length - 1) {
            acc[part] = value;
          } else if (!acc[part]) {
            // Create an empty object if it doesn’t exist
            acc[part] = {};
          }

          return acc[part];
        }
      }, updatedData);

      return updatedData;
    });
  };

  const handleImageChange = (file, path) => {
    if (!file) return;

    // Generate a preview URL for the image - to be modified later for preview.
    // const previewURL = URL.createObjectURL(file);

    setBlogData((prevData) => {
      const updatedData = { ...prevData };
      const parts = path.split('.');

      parts.reduce((acc, part, i) => {
        // Check if this part is an array index
        const arrayMatch = part.match(/^(.+)\[(\d+)\]$/);

        if (arrayMatch) {
          const arrayName = arrayMatch[1];
          const index = parseInt(arrayMatch[2], 10);

          // Ensure the array exists
          if (!acc[arrayName]) acc[arrayName] = [];
          // Ensure the array item exists
          if (!acc[arrayName][index]) acc[arrayName][index] = {};

          // If this is the last part, set the file and preview URL
          if (i === parts.length - 1) {
            acc[arrayName][index] = {
              ...acc[arrayName][index],
              file,
              // previewURL,
            };
          }

          return acc[arrayName][index];
        } else {
          // If this is the last part, set the file and preview URL
          if (i === parts.length - 1) {
            acc[part] = { file /*previewURL*/ };
          } else if (!acc[part]) {
            // Create an empty object if it doesn’t exist
            acc[part] = {};
          }

          return acc[part];
        }
      }, updatedData);

      return updatedData;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!blogData.title) newErrors.title = 'Title is required';
    if (!blogData.summary) newErrors.summary = 'Summary is required';
    if (blogData.sections && blogData.sections.length > 0) {
      blogData.sections.forEach((section, index) => {
        if (!section.sectionTitle)
          newErrors[`sections[${index}].sectionTitle`] =
            'Section title is required';
        if (!section.sectionContent)
          newErrors[`sections[${index}].sectionContent`] =
            'Section Content is required';
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addSection = () =>
    setBlogData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData.sections,
        {
          sectionTitle: '',
          sectionContent: '',
          sectionImage: null,
          tips: '',
          funFact: '',
          subsections: [
            { subSectionTitle: '', subSectionContent: '', subSectionTips: '' },
          ],
        },
      ],
    }));

  const removeSection = (index) =>
    setBlogData((prevData) => ({
      ...prevData,
      sections: prevData.sections.filter((_, i) => i !== index),
    }));

  const addSubsection = (sectionIndex) => {
    setBlogData((prevData) => {
      const updatedSections = [...prevData.sections];
      updatedSections[sectionIndex].subsections.push({
        subSectionTitle: '',
        subSectionContent: '',
        tips: '',
      });
      return { ...prevData, sections: updatedSections };
    });
  };

  const removeSubsection = (sectionIndex, subsectionIndex) => {
    setBlogData((prevData) => {
      const updatedSections = [...prevData.sections];
      updatedSections[sectionIndex].subsections.splice(subsectionIndex, 1);
      return { ...prevData, sections: updatedSections };
    });
  };

  const handleSaveDraft = () => {
    if (validateForm()) {
      // Save as draft (replace with actual save logic)
      console.log('Saved as draft:', JSON.stringify(blogData));
    }
  };

  const handlePreview = () => {
    if (validateForm()) {
      // Show preview (replace with actual preview logic)
      console.log('Preview:', blogData);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      // Save with status "SUBMITTED"
      const submittedData = { ...blogData, status: 'SUBMITTED' };
      console.log('Submitted:', JSON.stringify(submittedData));
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" align="center">
        Create Blog Post
      </Typography>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        required
        value={blogData.title}
        onChange={(e) => handleInputChange(e, 'title')}
        error={Boolean(errors.title)}
        helperText={errors.title}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Summary"
        variant="outlined"
        fullWidth
        required
        multiline
        rows={4}
        value={blogData.summary}
        onChange={(e) => handleInputChange(e, 'summary')}
        error={Boolean(errors.summary)}
        helperText={errors.summary}
        sx={{ mb: 2 }}
      />
      <ImageUpload
        onFilesChange={(file) => handleImageChange(file, 'titleImage')}
        // uploadText={'Drag & Drop, click to Browse for Blog Title Image '}
        // images={blogData.titleImage}
        label={'Drag & Drop, click to Browse for Blog Title Image'}
      />

      {blogData.sections.map((section, index) => (
        <Box
          key={index}
          sx={{
            m: 2,
            p: 2,
            border: '1px solid #ccc',
            borderRadius: 2,
            bgcolor: 'lightgray',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6">Section {index + 1}</Typography>
          <TextField
            label="Section Title"
            fullWidth
            required
            value={section.sectionTitle}
            onChange={(e) =>
              handleInputChange(e, `sections[${index}].sectionTitle`)
            }
            error={Boolean(errors[`sections[${index}].sectionTitle`])}
            helperText={errors[`sections[${index}].sectionTitle`]}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Section Description"
            fullWidth
            required
            multiline
            rows={3}
            value={section.sectionContent}
            onChange={(e) =>
              handleInputChange(e, `sections[${index}].sectionContent`)
            }
            error={Boolean(errors[`sections[${index}].sectionContent`])}
            helperText={errors[`sections[${index}].sectionContent`]}
            sx={{ mb: 2 }}
          />
          <ImageUpload
            onFilesChange={(file) =>
              handleImageChange(file, `sections[${index}].sectionImage`)
            }
            label={'Drag & Drop, click to Browse for Image for the section'}
          />

          <TextField
            label="Tips (if any)"
            fullWidth
            value={section.tips}
            onChange={(e) => handleInputChange(e, `sections[${index}].tips`)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Fun Fact (if any)"
            fullWidth
            value={section.funFact}
            onChange={(e) => handleInputChange(e, `sections[${index}].funFact`)}
            sx={{ mb: 2 }}
          />

          {section.subsections &&
            section.subsections.length > 0 &&
            section.subsections.map((subsection, subsectionIndex) => (
              <Box
                key={subsectionIndex}
                sx={{
                  mb: 2,
                  mx: 5,
                  p: 2,
                  border: '1px solid #ccc',
                  borderRadius: 2,
                  textAlign: 'left',
                  bgcolor: 'lightblue',
                }}
              >
                <Typography variant="h6">
                  Subsection {subsectionIndex + 1}
                </Typography>
                <TextField
                  label="Subsection Title"
                  fullWidth
                  required
                  value={subsection.subSectionTitle}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      `sections[${index}].subsections[${subsectionIndex}].subSectionTitle`
                    )
                  }
                  // error={Boolean(errors[`sections[${index}].sectionTitle`])}
                  // helperText={errors[`sections[${index}].sectionTitle`]}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Subsection Description"
                  fullWidth
                  required
                  multiline
                  rows={3}
                  value={subsection.subSectionContent}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      `sections[${index}].subsections[${subsectionIndex}].subSectionContent`
                    )
                  }
                  // error={Boolean(errors[`sections[${index}].sectionContent`])}
                  // helperText={errors[`sections[${index}].sectionContent`]}
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => removeSubsection(index, subsectionIndex)}
                >
                  Delete Subsection
                </Button>
              </Box>
            ))}
          <Box>
            <Button
              variant="outlined"
              sx={{ mb: 2, backgroundColor: 'lightblue' }}
              onClick={() => addSubsection(index)}
            >
              Add Subsection
              <IconButton color="primary">
                <AddCircleOutline />
              </IconButton>
            </Button>
          </Box>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => removeSection(index)}
          >
            Delete Section
          </Button>
        </Box>
      ))}
      <Box>
        <Button variant="outlined" sx={{ mb: 2 }} onClick={addSection}>
          Add Section
          <IconButton color="primary">
            <AddCircleOutline />
          </IconButton>
        </Button>
      </Box>

      <TextField
        label="Disclaimer (if any)"
        fullWidth
        multiline
        rows={3}
        value={blogData.disclaimer}
        onChange={(e) => handleInputChange(e, 'disclaimer')}
      />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: 3,
          button: {
            flex: 'none',
          },
        }}
      >
        <Button variant="outlined" onClick={handleSaveDraft} sx={{ mr: 1 }}>
          Save as Draft
        </Button>
        <Button variant="outlined" onClick={handlePreview} sx={{ mr: 1 }}>
          Preview
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default CreateBlog;
