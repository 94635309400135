import * as React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@mui/material';
import CreateBlog from './admin/CreateBlog';
import { Book, Draw, PollOutlined, Analytics } from '@mui/icons-material';
import { useMediaQueryContext } from '../context/MediaQueryProvider';
import CreateActivity from './admin/CreateActivity';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQueryContext();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="basic tabs example"
        >
          <Tab
            icon={<Book />}
            iconPosition="start"
            label={isMobile ? '' : 'Create Blog'}
            {...a11yProps(0)}
          />
          <Tab
            icon={<Draw />}
            iconPosition="start"
            label={isMobile ? '' : 'Create Activity'}
            {...a11yProps(1)}
          />
          <Tab
            icon={<PollOutlined />}
            iconPosition="start"
            label={isMobile ? '' : 'Create Survey'}
            {...a11yProps(2)}
          />
          <Tab
            icon={<Analytics />}
            iconPosition="start"
            label={isMobile ? '' : 'View Survey Results'}
            {...a11yProps(3)}
          />
          <Tab
            icon={<Analytics />}
            iconPosition="start"
            label={isMobile ? '' : 'Audit'}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CreateBlog />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CreateActivity />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        Item Four
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        Item Five - Audit activities - Publishing Blogs and activities for now.
      </CustomTabPanel>
    </Box>
  );
}
