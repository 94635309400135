// src/About.js

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Avatar,
  Card,
  CardContent,
} from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/system';

const MotionBox = motion.create(Box);

const Section = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const TeamCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(2),
}));

const TeamAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  marginRight: theme.spacing(2),
}));

const About = () => {
  const teamMembers = [
    {
      name: 'Rakesh Shah',
      role: 'Founder',
      description:
        'Rakesh, a visionary leader with over two decades of invaluable experience, is the driving force behind our organization. His relentless passion for creating truly impactful solutions that uplift humanity has been the guiding light throughout our journey. With an unwavering commitment to excellence and a deep understanding of the challenges we face, his vision continues to inspire us to push boundaries and make a lasting difference in the world.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      name: 'Lakshman Kumar',
      role: 'Co-Founder',
      description:
        'Lakshman, a visionary technologist, seamlessly blends innovation and strategic acumen. His unparalleled creativity and meticulous attention to detail have propelled him to become a pillar of excellence, guiding organizations to soar to unprecedented heights.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      name: 'Satya Ranjan',
      role: 'Co-Founder',
      description:
        'Satya spearheads the technical innovations that drive our company forward. His visionary approach instills a spirit of fresh thinking and creative problem-solving within our organization. Under his leadership, we are inspired to approach every challenge with a unique perspective, ensuring that we consistently deliver solutions tailored to the evolving needs of our dynamic, youthful customer base',
      imageUrl: 'https://via.placeholder.com/150',
    },
  ];

  return (
    <Container>
      {/* Mission Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
          viewport={{ once: true }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            We are on a Mission
          </Typography>
          <Typography variant="body1" align="justify" paragraph>
            At our core, we believe that every child is a radiant source of
            boundless potential, brimming with unique curiosity and limitless
            imagination. Our mission is to be the catalyst that ignites their
            inner spark, guiding them on a transformative journey of
            self-discovery and growth. Through meticulously crafted, engaging,
            and educational activities infused with the magic of fun, we nurture
            the inherent brilliance within each child. Our commitment is to
            create an environment where their individuality is celebrated, their
            inquisitiveness is fueled, and their dreams take flight. We are not
            merely educators; we are dream weavers, passionate about empowering
            the next generation to fearlessly explore their boundless potential
            and shape a brighter tomorrow for themselves and the world around
            them.
          </Typography>
        </MotionBox>
        <Box textAlign="center">
          <img
            src="/images/about_page_mission.webp"
            alt="Mission Statement Image"
            style={{ maxWidth: '100%', marginTop: 20 }}
          />
        </Box>
      </Section>
      {/* Vision Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.2 }}
          viewport={{ once: true }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Our Vision
          </Typography>
          <Typography variant="body1" align="justify" paragraph>
            Our vision is to create a global community where creative
            individuals from all walks of life come together to illuminate the
            brilliance that resides within every child. We aspire to be the
            beacon that ignites a transformative spark, transcending boundaries
            and empowering the next generation to reach their boundless
            potential. By fostering a diverse and inclusive environment, we aim
            to harness the collective creativity of individuals from around the
            world, providing a platform for them to share their unique
            perspectives, talents, and experiences. Through this collaborative
            tapestry of diverse minds, we strive to craft educational
            experiences that not only nurture intellectual growth but also
            ignite the flames of curiosity, imagination, and self-expression.
            Our ambition is to touch the lives of children across the globe,
            transcending geographical, cultural, and socioeconomic barriers. We
            envision a world where every child, regardless of their
            circumstances, has access to the tools and resources necessary to
            unlock their innate potential and shape their destinies. By igniting
            the spark within each child, we aspire to cultivate a generation of
            change makers, innovators, and visionaries who will fearlessly
            challenge boundaries, embrace diversity, and create a brighter, more
            harmonious world for all.
          </Typography>
        </MotionBox>
      </Section>

      {/* Goals Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ type: 'spring', stiffness: 50, duration: 1.2 }}
          viewport={{ once: true }}
        >
          <Typography variant="h4" gutterBottom>
            Our Goals
          </Typography>
          <Box component="ul" sx={{ paddingLeft: '20px', listStyle: 'disc' }}>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              viewport={{ once: true }}
            >
              <b>Encourage Exploration and Discovery: </b>
              <br /> We strive to create an environment where kids can freely
              explore their interests, ask questions, and discover new passions.
              Our activities are designed to spark their imagination and
              stimulate their natural curiosity.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              viewport={{ once: true }}
            >
              <b>Promote Holistic Development:</b>
              <br /> We believe in nurturing the whole child – their cognitive,
              physical, social, and emotional well-being. Our activities cater
              to various developmental areas, ensuring a well-rounded growth
              experience.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              viewport={{ once: true }}
            >
              <b>Foster Creativity and Innovation:</b>
              <br /> In a rapidly changing world, we recognize the importance of
              cultivating creativity and innovative thinking. Our activities
              encourage kids to think outside the box, embrace their unique
              perspectives, and develop problem-solving skills.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              <b>Inspire a Love for Learning:</b> <br /> We aim to instill a
              lifelong love for learning in every child. By making learning fun,
              engaging, and relevant to their interests, we hope to spark a
              passion that will continue to fuel their curiosity and thirst for
              knowledge.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              <b>Empower Parents and Caregivers:</b>
              <br /> We understand the importance of parental and caregiver
              involvement in a child's development. Our platform provides
              resources, tools, and guidance to support and empower families in
              nurturing their children's growth.
            </motion.li>
          </Box>
        </MotionBox>
      </Section>

      {/* Team Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <Typography variant="h4" gutterBottom>
            Meet the Team
          </Typography>
          <Grid container spacing={3}>
            {teamMembers.map((member) => (
              <Grid item xs={12} md={6} key={member.name}>
                <TeamCard>
                  <TeamAvatar src={member.imageUrl} alt={member.name} />
                  <CardContent>
                    <Typography variant="h6">{member.name}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {member.role}
                    </Typography>
                    <Typography variant="body2">
                      {member.description}
                    </Typography>
                  </CardContent>
                </TeamCard>
              </Grid>
            ))}
          </Grid>
        </MotionBox>
      </Section>

      {/* Impact Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Our Impact
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Our work inspires kids to explore their creativity and contributes
            to a better world for humanity. Join us on our mission to make a
            positive difference.
          </Typography>
        </MotionBox>
      </Section>
    </Container>
  );
};

export default About;
