import React, { useState } from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { CloudUpload } from '@mui/icons-material';

const ImageUpload = ({ onFilesChange, multiple = false, label }) => {
  const [files, setFiles] = useState([]);

  // Handle file drop
  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => {
      let updatedFiles = multiple
        ? [...prevFiles, ...acceptedFiles]
        : [acceptedFiles[0]]; // Only keep one file if multiple is false

      if (onFilesChange) {
        onFilesChange(updatedFiles);
      }
      return updatedFiles;
    });
  };

  // Handle removing a file
  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      if (onFilesChange) {
        onFilesChange(updatedFiles);
      }
      return updatedFiles;
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': [], // Correctly defines the MIME type for image uploads
    },
    multiple: multiple,
  });

  return (
    <Box>
      {/* Dropzone */}
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #ccc',
          borderRadius: '8px',
          textAlign: 'center',
          cursor: 'pointer',
          mb: 2,
        }}
      >
        <input {...getInputProps()} />
        <p>{label}</p>
        <CloudUpload sx={{ fontSize: 36, color: '#888' }} />
      </Box>

      {/* File Chips */}
      <Box>
        {files.map((file, index) => (
          <Chip
            key={index}
            label={file.name}
            onDelete={() => handleRemoveFile(index)}
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ImageUpload;
