import React, { useState, useEffect } from 'react';
import { fetchBlogData } from '../api/blogService';
import {
  NO_BLOGS_MESSAGE_DESC,
  NO_BLOGS_MESSAGE_TITLE,
} from '../constants/errorMessages';
import ErrorComponentView from '../components/error/ErrorComponentView';
import BlogListView from '../components/blog/BlogListView';
import LoadingUI from '../components/loading/LoadingUI';

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blogError, setBlogError] = useState(false);

  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      try {
        // fetching 100 blogs, which is sorted by creation date in backend
        const response = await fetchBlogData(1, 100);
        setBlogData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setBlogError(true);
        console.error(error.message);
      }
    };

    fetchBlog();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingUI />
      ) : (
        <>
          {blogError ? (
            <ErrorComponentView
              title={NO_BLOGS_MESSAGE_TITLE}
              description={NO_BLOGS_MESSAGE_DESC}
            />
          ) : (
            <BlogListView blogData={blogData} blogsPerPage={10} />
          )}
        </>
      )}
    </>
  );
};

export default Blog;
