import apiClient from './apiClient';
import ENDPOINTS from './endpoints';
import axios from 'axios';

export const uploadFileToS3 = async (bucketName, filePath, mimeType) => {
  try {
    const signedUrlResponse = await apiClient.get(
      ENDPOINTS.S3_UPLOAD_URL(bucketName, filePath, mimeType)
    );
    console.debug('s3 signed url', signedUrlResponse.data);
    const { data } = signedUrlResponse; // presigned url will be in data.url

    if (!data.url) {
      throw 'presigned url not found';
    }
    const uploadResponse = await axios.put(data.url, filePath, {
      headers: {
        'Content-Type': mimeType, // Set the content type of the file being uploaded
      },
    });
    if (uploadResponse.status === 200) {
      console.log('file uploaded to S3');
      return `${bucketName}/${filePath}`;
    }
    throw 'uploading to S3 failed with presigned url';
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
};
